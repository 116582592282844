<template>
  <onboarding-layout back-route-name="OnboardingName">
    <h1 class="h2 mb-2">
      Welke avatar wil je?
    </h1>

    <p class="color-text mb-3">
      Deze kun je later vervangen door je eigen profielfoto.
    </p>

    <form action="" method="POST" @submit.prevent="onSubmit">
      <Errors :errors="errors" />

      <div class="row avatar-row">
        <div class="col">
          <input
            class="avatar-checkbox"
            type="radio"
            name="avatar"
            id="avatar1"
            value="1"
            v-model="formAvatar"
          >
          <label for="avatar1">
            <div class="avatar-img-wrapper">
              <img
                :src="`/img/themes/${theme.value}/avatar-female.svg`"
                alt="Illustratie van vrouwelijke huisdier bezitter"
              >
            </div>
            <span class="visually-hidden">
              Avatar met lang haar
            </span>
          </label>
        </div>

        <div class="col">
          <input
            class="avatar-checkbox"
            type="radio"
            name="avatar"
            id="avatar2"
            value="2"
            v-model="formAvatar"
          >
          <label for="avatar2">
            <div class="avatar-img-wrapper">
              <img
                :src="`/img/themes/${theme.value}/avatar-male.svg`"
                alt="Illustratie van mannelijke huisdier bezitter"
              >
            </div>
            <span class="visually-hidden">
              Avatar met kort haar
            </span>
          </label>
        </div>
      </div>

      <Button classes="avatar-button" button-type="submit">
        Naar volgende stap
      </Button>
    </form>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';
import Button from '../../components/Button.vue';

export default {
  inject: ['theme'],

  components: {
    Button,
    Errors,
    OnboardingLayout,
  },

  data() {
    return {
      errors: [],
      formAvatar: this.$store.state.onboarding.avatar,
    };
  },

  created() {
    this.$store.commit('resetLayout', {
      showDog: this.petType === 'dog',
      showCat: this.petType === 'cat',
      name: this.firstName,
      showCta: false,
      progress: 83.333,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '5-user-avatar',
    });
  },

  methods: {
    validate() {
      this.errors = [];

      if (!this.formAvatar) {
        this.errors.push('Selecteer een avatar om verder te gaan');
      }

      return this.errors.length === 0;
    },

    onSubmit() {
      if (!this.validate()) {
        return;
      }

      this.$store.commit('setAvatar', this.formAvatar);

      this.$router.push({ name: 'OnboardingEmail' });
    },
  },

  computed: {
    petType() {
      return this.$store.state.onboarding.petType;
    },

    firstName() {
      return this.$store.state.onboarding.firstName;
    },
  },
};
</script>

<style>
.avatar-row {
  max-width: 19rem;
  margin-left: auto;
  margin-right: auto;
}

.avatar-button {
  width: 17rem;
}

.avatar-checkbox {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.avatar-checkbox + label {
  cursor: pointer;
}

.avatar-checkbox + label:hover .avatar-img-wrapper {
  transform: scale(1.1);
}

.avatar-checkbox + label .avatar-img-wrapper {
  display: block;
  margin: 0 auto 2rem;
  width: 7rem;
  height: 7rem;
  position: relative;
  transition: transform .2s ease-out;
}

.avatar-checkbox + label .avatar-img-wrapper:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(154, 196, 13, .7) url(../../assets/check-white.svg) no-repeat center center;
  background-size: 40% auto;
  border-radius: 50%;
  opacity: 0;
  transition: opacity .2s ease-out;
}

.avatar-checkbox + label .avatar-img-wrapper img {
  display: block;
  max-width: 100%;
}

.avatar-checkbox:checked + label .avatar-img-wrapper:before {
  opacity: 1;
}

@media (min-width: 640px) {
  .avatar-checkbox + label img {
    width: 7rem;
  }
}
</style>
